<template>
    <div class="reportBillCostDetail">
        <div class="panel-box top-info-box">
            <el-row>
                <el-col :xs="12" :sm="8" :md="6" :lg="6" >
                    <span class="lable-text">营业日期：{{tableInfo.Rpt_Date}}</span>
                </el-col>
                <el-col :xs="12" :sm="8" :md="6" :lg="6">
                    <span class="lable-text">餐厅：{{tableInfo.Eat_RoomName}}</span>
                </el-col>
                <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                    <span class="lable-text">桌号：{{tableInfo.Eat_DeskName}}</span>
                </el-col>
            </el-row>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
        </div>
        <div class="panel-box table-box grey-table" ref="tableBox" >
            <el-table :data="tableList" ref="elTable"  style="width: 100%;height:100%"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                show-summary
                :summary-method="getSummaries"
                @cell-dblclick="orderDetail">
                <el-table-column fixed label="序号" type="index" width="50" />
                <el-table-column fixed prop="Eat_XFCode" align="left" label="菜品编码" width="100"></el-table-column>
                <el-table-column fixed prop="Eat_XFName" align="left" label="菜品名称" width="100">
                    <template #default="scope">
                        <span class="xfName">{{scope.row.Eat_XFName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="STDProfitRate" label="标准毛利率%" align="right" width="90" :formatter="priceFormat" data-format="number"></el-table-column>
                <el-table-column prop="Eat_XFSize" label="单位" width="52"> </el-table-column>
                <el-table-column prop="Eat_Number" label="实点数量" align="right" width="60">
                    <template #default="scope">
                        <span v-if="scope.row.Eat_Number>0">{{scope.row.Eat_Number}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Refund_Number" label="退菜数量" align="right" width="60">
                    <template #default="scope">
                        <span v-if="scope.row.Refund_Number>0">{{scope.row.Refund_Number}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Eat_SalePrice" label="折前单价" align="right" width="60" :formatter="priceFormat" data-format="number"></el-table-column>
                <el-table-column label="销售金额">
                    <el-table-column prop="Eat_SaleMoney" :label="data?.SaleMoneyType==1?'原价':'折前'" align="right" min-width="70" :formatter="priceFormat" data-format="number"></el-table-column>
                    <el-table-column prop="Eat_CheckMoney" label="折后" align="right" min-width="70" :formatter="priceFormat" data-format="number"></el-table-column>
                    <el-table-column prop="Eat_FactMoney" label="实收" align="right" min-width="70" :formatter="priceFormat" data-format="number"></el-table-column>
                    <el-table-column prop="NoTaxFactMoney" label="去税" align="right" min-width="70" :formatter="priceFormat" data-format="number"></el-table-column>
                </el-table-column>
                <el-table-column prop="Eat_CostMoney" label="成本金额" align="right" width="70" :formatter="priceFormat" data-format="number"></el-table-column>
                <el-table-column label="毛利率%">
                    <el-table-column prop="Rate_SaleMoney" :label="data?.SaleMoneyType==1?'原价':'折前'" align="right" min-width="70" :formatter="priceFormat" data-format="number"></el-table-column>
                    <el-table-column prop="Rate_CheckMoney" label="折后" align="right" min-width="70" :formatter="priceFormat" data-format="number"></el-table-column>
                    <el-table-column prop="Rate_FactMoney" label="实收" align="right" min-width="70" :formatter="priceFormat" data-format="number"></el-table-column>
                    <el-table-column prop="Rate_NoTaxFactMoney" label="去税" align="right" min-width="70" :formatter="priceFormat" data-format="number"></el-table-column>
                </el-table-column>
                <el-table-column prop="Eat_Czy" label="点单员" width="80"></el-table-column>
                <el-table-column prop="Eat_Time" label="点单时间" min-width="80" :formatter="formatter"></el-table-column>
                <el-table-column prop="Eat_KitName" label="厨师姓名" width="80"></el-table-column>
                <el-table-column prop="Eat_MasName" label="下单备注" width="110">
                    <template #default="scope">
                        <el-tooltip effect="dark" :content="scope.row.Eat_MasName" placement="top">
                            <div>{{ scope.row.Eat_MasName }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
/**菜品毛利详情表 */
export default {
    name:"reportBillCostDetail",
    props:{
        data:{
            type:Object,
            default:null
        }
    },
    data(){
        return {
            tableList:[]
        }
    },
    computed:{
        tableInfo(){
            if(this.tableList?.length>0){
                return {
                    Rpt_Date:(new Date(this.tableList[0].Rpt_Date)).Format('yyyy-MM-dd'),
                    Eat_RoomName:this.tableList[0].Eat_RoomName,
                    Eat_DeskName:this.tableList[0].Eat_DeskName
                }
            }
            return {}
        },
        //合计
        total(){
            let total={
                Eat_SaleMoney:0,
                Eat_CheckMoney:0,
                Eat_FactMoney:0,
                NoTaxFactMoney:0,
                Eat_CostMoney:0,
            };
            this.tableList?.forEach((item)=>{
                total.Eat_SaleMoney+=item.Eat_SaleMoney;
                total.Eat_CheckMoney+=item.Eat_CheckMoney;
                total.Eat_FactMoney+=item.Eat_FactMoney;
                total.NoTaxFactMoney+=item.NoTaxFactMoney;
                total.Eat_CostMoney+=item.Eat_CostMoney;
            })
            total.Rate_SaleMoney=total.Eat_SaleMoney==0?0: (total.Eat_SaleMoney-total.Eat_CostMoney)/total.Eat_SaleMoney*100;
            total.Rate_CheckMoney=total.Eat_CheckMoney==0?0: (total.Eat_CheckMoney-total.Eat_CostMoney)/total.Eat_CheckMoney*100;
            total.Rate_FactMoney=total.Eat_FactMoney==0?0: (total.Eat_FactMoney-total.Eat_CostMoney)/total.Eat_FactMoney*100;
            total.Rate_NoTaxFactMoney=total.NoTaxFactMoney==0?0: (total.NoTaxFactMoney-total.Eat_CostMoney)/total.NoTaxFactMoney*100;
            return total;
        }
    },
    watch:{
        data:{
            handler(){
                if(this.data){
                   this.$nextTick(()=>{
                        this.loadData();
                   })
                }
            },
            immediate:true,
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
    },
    methods:{
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            this.$nextTick(()=>{
                if(this.$refs.tableBox){
                    let height=this.$refs.tableBox.clientHeight - 70-50;
                    this.pagesize=parseInt(height/50);
                }
            })
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /*时间格式 */
        formatter(row, column,cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd');
        },
        //穿透到 菜品原材料分析表
        orderDetail(row,column){
            if(column.property=="Eat_XFName"){
                this.$emit("addFrame",{
                    key:row.Eat_XFBMID,
                    data:{
                        Eat_XFBMID:row.Eat_XFBMID,
                        Rpt_Date:this.tableInfo.Rpt_Date,
                        Eat_RoomName:row.Eat_RoomName,
                        Eat_XFName:row.Eat_XFName
                    },
                    title:'菜品原材料分析表',
                    url:'rawMaterialAnalysisReport'
                });
            }
        },
        //合计
        getSummaries({ columns, data }){
            const sums= []; columns.forEach((column, index) => {
                if(this.total[column.property]!=undefined){
                    sums[index]=this.priceFormat(null,null, this.total[column.property]);
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            this.$excelCommon.elTableToExcel({
                elTable:this.$refs.elTable,
                titleName:"菜品毛利详情表",
                list:this.tableList,
                headArr:[
                    [
                        {value:'营业日期：'+this.tableInfo?.Rpt_Date,col:3}, 
                        {value:'餐厅：'+this.tableInfo?.Eat_RoomName,col:3}, 
                        {value:'桌号：'+this.tableInfo?.Eat_DeskName,col:3},
                    ],
                ]
            })
        },
        /**查询 */
        loadData(){
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_AutoID:this.data?.Eat_AutoID,
                Rpt_Date:this.data?.Rpt_Date,
                SaleMoneyType:this.data?.SaleMoneyType==1?1:2,//折前金额类型  1.按套餐内菜品原价统计  2、按套餐售价统计
                IsHideMarkedBills:this.$global.isLableData //是否隐藏带标签的订单
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.post("Bestech.CloudPos.FoodCostReport",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody
                    this.setTablePageSize();
                }else{
                    this.tableList=[];
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
    }
}
</script>

<style lang="scss">
    @import "./reportBillCostDetail.scss"
</style>